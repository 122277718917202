
    import { kebabCase } from 'lodash';
    import { mapState } from 'vuex';

    import { CloseIcon } from '@components/icons';

    import BaseModal from './BaseModal';

    export default {
        name: 'BoosterModal',

        components: {
            CloseIcon,
        },

        extends: BaseModal,

        i18nOptions: { namespaces: ['skill_game'] },

        props: {
            canHeal: {
                type: Boolean,
                default: false,
            },
        },

        data () {
            return {
                modalName: 'booster',
            };
        },

        computed: {
            ...mapState(['skillGame']),
        },

        methods: {
            getBoosterButtonDisabled (booster) {
                if (booster === 'heal' && !this.canHeal) {
                    return true;
                }

                return this.skillGame?.boosters[booster] === 0;
            },

            getBoosterImageSource (booster) {
                return require(`../../img/booster-${kebabCase(booster)}.svg`);
            },

            selectBooster (booster) {
                this.closeModal(booster);
            },
        },
    };
