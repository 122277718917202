
    import { mapState } from 'vuex';

    import { CloseIcon } from '@components/icons';

    import BaseModal from './BaseModal';

    export default {
        name: 'SweepstakesPrizeModal',

        components: {
            CloseIcon,
        },

        extends: BaseModal,

        i18nOptions: { namespaces: ['sweepstakes_prize_modal'] },

        data () {
            return {
                modalName: 'sweepstakes-prize',
            };
        },

        computed: {
            ...mapState(['award']),
        },
    };
