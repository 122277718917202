
    import SocialShare from '@components/SocialShare.vue';
    import SparkleIcon from '@components/icons/SparkleIcon.vue';

    import BaseModal from './BaseModal';

    export default {
        name: 'GameOverModal',

        components: {
            SocialShare,
            SparkleIcon,
        },

        extends: BaseModal,

        i18nOptions: { namespaces: ['game_over_modal', 'skill_game'] },

        props: {
            completedAchievements: {
                type: Array,
                default: () => [],
            },

            newHighScore: {
                type: Boolean,
                default: false,
            },

            score: {
                type: Number,
                default: 0,
            },
        },

        data () {
            return {
                modalName: 'game-over',

                numberFormatter: null,
            };
        },

        computed: {
            shouldShowAchievements () {
                return this.completedAchievements?.length > 0 || false;
            },

            scoreFormatted () {
                return this.numberFormatter?.format(this.score) || '0';
            },
        },

        created () {
            this.numberFormatter = new Intl.NumberFormat(this.app.locale);
        },
    };
