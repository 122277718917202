
    import { mapState, mapGetters, mapActions } from 'vuex';

    import TheHeader from '@components/TheHeader.vue';
    import TheFooter from '@components/TheFooter.vue';
    import { CookieGate } from '@public/vendor/cookie-gate';
    import openModal, { SessionExpirationModal } from './modals';
    import logClientError from './client-error';
    import webAnalytics from './plugins/WebAnalytics';

    export default {
        name: 'App',

        components: {
            CookieGate,
            TheHeader,
            TheFooter,
        },

        i18nOptions: { namespaces: ['global', 'cookie_gate'] },

        provide: {
            $webAnalytics: webAnalytics,
        },

        computed: {
            ...mapState([
                'app',
                'profile',
                'ui',
            ]),
            ...mapGetters({
                loggedIn: 'profile/loggedIn',
                isSessionExpired: 'profile/isSessionExpired',
            }),

            shouldShowFooter () {
                return !['faq', 'skill-game'].includes(this.ui.pageName);
            },
        },

        watch: {
            isSessionExpired (value) {
                if (value) {
                    this.openSessionExpirationModal();
                }
            },
        },

        // If a fatal error occurs rendering any of our descendent components, try
        // to navigate to the error page to preserve some UX.
        // https://vuejs.org/v2/api/#errorCaptured
        errorCaptured (err) {
            this.$router.push('/error');
            // Forward fatal Vue component errors, i.e. those that caused us to display
            // the error page here, to './client-error' and its server-side logging.
            logClientError(err, { fatal: true });
        },

        methods: {
            ...mapActions({
                logOut: 'profile/logOut',
            }),

            async openSessionExpirationModal () {
                await openModal(SessionExpirationModal);

                // After the modal is closed.
                this.logOut();
                this.$router.replace('/');
            },
        },
    };
