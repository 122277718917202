
    import { mapGetters, mapState } from 'vuex';

    import BackIcon from '@components/icons/BackIcon.vue';
    import StarbucksForLifeLogo from '@components/StarbucksForLifeLogo.vue';

    export default {
        name: 'TheHeader',

        components: {
            BackIcon,
            StarbucksForLifeLogo,
        },

        i18nOptions: { namespaces: ['global'] },

        computed: {
            ...mapGetters({
                loggedIn: 'profile/loggedIn',
            }),

            ...mapState(['ui']),

            backButtonTo () {
                return ['skill-game-instructions'].includes(this.ui.pageName) ?
                    '/holiday-run' : '/gameboard';
            },

            logoColor () {
                return [].includes(this.ui.pageName) ? 'white' : 'green';
            },

            logoSize () {
                return [
                    'landing',
                    'skill-game-instructions',
                    'welcome',
                ].includes(this.ui.pageName) ? 'large' : 'small';
            },

            showBackButton () {
                return this.loggedIn && [
                    'oamoe',
                    'oamoe-limited',
                    'oamoe-thanks',
                    'skill-game-instructions',
                    'welcome',
                ].includes(this.ui.pageName);
            },
        },

        mounted () {
            // Focus the h1 element to force screen readers back to the top after a navigation.
            // This will ensure screen readers will read the page again.
            // https://a11y-guidelines.orange.com/en/articles/single-page-app/#notify-user-of-page-changes
            this.$router.afterEach(() => this.$refs.h1.focus());
        },
    };
