
    import { Hooper, Slide } from 'hooper';
    import 'hooper/dist/hooper.css';

    import { CloseIcon } from '@components/icons';

    import BaseModal from './BaseModal';

    export default {
        name: 'PrizeInformationModal',

        components: {
            CloseIcon,
            Hooper,
            Slide,
        },

        extends: BaseModal,

        i18nOptions: { namespaces: ['prize_information_modal'] },

        props: {
            prizeId: {
                type: String,
                default: '',
            },

            prizeType: {
                type: String,
                default: '',
            },
        },

        data () {
            return {
                modalName: 'prize-information',

                currentSlide: 0,
                hooperSettings: {
                    wheelControl: false,
                },
            };
        },

        computed: {
            currentSlideDisplay () {
                return this.currentSlide + 1;
            },

            maxSlideDisplay () {
                return this.sortedPrizes?.length || 0;
            },

            selectedPrize () {
                return this.prizeId ?
                    this.sortedPrizes.find(
                        prize => prize.id && prize.id[this.app.userCountry] === this.prizeId,
                    ) : false;
            },

            shouldShowCarouselControls () {
                return !this.selectedPrize && (this.sortedPrizes?.length || 0) > 1;
            },

            sortedPrizes () {
                const prizes = this.$t('prizes');

                let sortedPrizes = Object.keys(prizes)
                    .filter(key => !key.startsWith('.'))
                    // Numeric sort
                    .sort((a, b) => a - b)
                    .map(key => prizes[key])
                    // Filter by country
                    .filter(prize => !prize.country || prize.country === this.app.userCountry);

                if (this.prizeType) {
                    sortedPrizes = sortedPrizes.filter(
                        prize => prize.type === this.$t(`types.${this.prizeType}`),
                    );
                }

                return sortedPrizes;
            },
        },

        methods: {
            badgeImageSource (prize) {
                try {
                    const prizeBadge = prize.badge || 'general';
                    return require(`../../img/prizes/${prizeBadge}.svg`);
                }
                catch (error) {
                    // Swallow error and return placeholder data URI for missing image
                    return 'data:image/gif;base64,R0lGODlhAQABAIAAAP7//wAAACH5BAAAAAAALAAAAAABAAEAAAICRAEAOw==';
                }
            },

            handleNextButtonClick () {
                this.$refs.hooper.slideNext();
            },

            handlePrevButtonClick () {
                this.$refs.hooper.slidePrev();
            },

            updateCurrentSlide (payload) {
                this.currentSlide = payload.currentSlide;
            },
        },
    };
