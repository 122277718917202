import { camelCase, startCase } from 'lodash';
import { i18next } from './i18n';

import { logGdsMessage, trackGdsPageView } from './plugins/GdsPlugin';

import store from './store';

i18next.loadNamespaces('gdsText');

const
    debugGlobalObject = false, // Toggles console messages about the global object.
    isLocalDev = document.location.href.match(/localhost:808/),
    { appInfo } = window.uo_data,
    // These exceptions represent pages and modals for which
    // the page view:
    //   1) Must be called from within the Vue file for some reason.
    //   2) Should not be called at all.
    // Please use these exceptions sparingly. (:
    passivePageViewExceptions = new Set(['dashboard']),
    passiveModalPageViewExceptions = new Set(['sessionExpirationModal', 'pollModal']),

    // Martech has asked that we always re-fire the parent pageView once the modal is closed.
    // This gives us a place to add pages which may need to be called from various locations,
    // such as multiple types of modals. The latest viewed page in this Set will be added to
    // the gds Vuex store under pageViewData.
    globallyCalledPageViews = new Set(['game', 'skillGame']),

    collectAndWinPrizes = new Set([
        '8164bc1a280d11eea7c673a309b52061',
        '8179b2e6280d11eea7c673a309b52061',
        '819cc39e280d11eea7c673a309b52061',
        '81b93a1a280d11eea7c673a309b52061',
        '81cdd7b8280d11eea7c673a309b52061',
        '81fd642e280d11eea7c673a309b52061',
        '822f842c280d11eea7c673a309b52061',
        '82458542280d11eea7c673a309b52061',
        '82579840280d11eea7c673a309b52061',
        '82996450280d11eea7c673a309b52061',
        '82ae8592280d11eea7c673a309b52061',
        '87ab5536280611eead34fe7009b52061',
        '87bdfaba280611eead34fe7009b52061',
        '87d05d72280611eead34fe7009b52061',
        '882a3f86280611eead34fe7009b52061',
        '88608d2a280611eead34fe7009b52061',
        '88968254280611eead34fe7009b52061',
        '88b71c12280611eead34fe7009b52061',
        '8925fa92280611eead34fe7009b52061',
        '894ac228280611eead34fe7009b52061',
        '8971cd6e280611eead34fe7009b52061',
        '8997abce280611eead34fe7009b52061',
    ]);

appInfo.csid = undefined; // Need to check against this later to ensure it's only set once

// Update on state changes
store.subscribe(async (mutation, state) => {
    try {
        // Set appInfo values
        appInfo.pageURL = document.location.href;
        appInfo.screenName = getGdsPageTemplateName(state.ui);
        appInfo.appLanguage =
            state.app.userLanguage ? (state.app.userLanguage).toUpperCase() : '';
        appInfo.appName = i18next.t('gdsText:globalObject.appInfo.appName');
        appInfo.appRegion = state.app.userCountry;

        if (state.profile.csid && !appInfo.csid) {
            appInfo.csid = state.profile.csid;
            logInToAnalytics(state.profile.csid);
        }

        appInfo.environment = getGdsEnvironment();

        // pageViews
        if (mutation.type === 'ui/pageEnter') {
            const
                dimensions = {},
                hash = document.location.hash.replace('#/', ''),
                camelCasedHash = camelCase(hash);
            let pageName = camelCase(mutation.payload.pageName) || camelCasedHash;

            logGdsMessage({
                item: `Working pageName is: ${pageName}`,
                message: '',
                trackingMethod: 'pageView',
                type: 'info',
            });

            if (!mutation.payload.pageName) {
                logGdsMessage({
                    item: `pageView for ${hash}`,
                    // The following spaces after the newline are intentional.
                    message: 'No pageName specified for this template.\n      Derived pageName from hash value.',
                    trackingMethod: 'pageView',
                    type: 'info',
                });
            }
            else if (pageName === 'error') {
                if (mutation.payload.error === 'ineligible_partner' || hash.includes('partner')) {
                    dimensions.screenDetail = i18next.t('gdsText:screenDetails.errorPartner');
                }
                else if (
                    hash.includes('country') ||
                    hash.includes('loyalty') ||
                    hash.includes('email') ||
                    hash.includes('oauth')
                ) {
                    dimensions.screenDetail = i18next.t(`gdsText:screenDetails.${camelCasedHash}`);
                }
                else {
                    dimensions.screenDetail = i18next.t('gdsText:screenDetails.errorGeneric');
                }
            }
            else if (pageName === 'welcome') {
                dimensions.firstSession = mutation.payload.isFirstSession;
            }
            else if (pageName === 'game') {
                dimensions.playsRemaining = state.profile.plays_remaining;
            }
            else if (pageName === 'skillGame') {
                dimensions.currentLevel = state.profile.skillgame;
                dimensions.screenDetail =
                    i18next.t(`gdsText:screenDetails.${pageName}`, { currentLevel: dimensions.currentLevel });
            }
            else if (pageName === 'landing') {
                if (state.app.phase === 'expired') {
                    pageName = 'expired';
                }
                else if (state.app.phase === 'pre-launch') {
                    pageName = 'preLaunch';
                }
                else if (state.app.phase === 'survey') {
                    pageName = 'postGameSurvey';
                }
            }

            // Ensure screenDetail is either initialized or preserved
            dimensions.screenDetail = dimensions.screenDetail || i18next.t(`gdsText:screenDetails.${pageName}`);

            // If this pageView can be fired from other components,
            // store the data for later but don't fire the view.
            if (globallyCalledPageViews.has(pageName)) {
                store.dispatch('gds/updatePageViewData', {
                    pageName: `${pageName}`,
                    category: i18next.t(`gdsText:templateNames.${pageName}`),
                    dimensions,
                });
            }

            // If this is not a special case, fire the page view
            if (!passivePageViewExceptions.has(pageName)) {
                trackGdsPageView({
                    category: i18next.t(`gdsText:templateNames.${pageName}`),
                    dimensions,
                });
            }
        }
        // "pageViews" for modals/overlays
        else if (mutation.type === 'ui/modalOpen') {
            const
                dimensions = {},
                hash = document.location.hash.replace('#/', ''),
                camelCasedHash = camelCase(hash);
            let
                gdsModalName =  camelCase(mutation.payload.modalName)  ||
                                mutation.payload.challengeId ||
                                camelCasedHash;

            if (gdsModalName === 'poll') {
                gdsModalName = mutation.payload.pollId;
            }

            logGdsMessage({
                item: `Working modalName is: ${gdsModalName}`,
                message: '',
                trackingMethod: 'pageView',
                type: 'info',
            });

            if (!mutation.payload.modalName && !mutation.payload.challengeId) {
                logGdsMessage({
                    item: `Modal pageView for ${hash || gdsModalName}`,
                    message: '\n[GDS] No modalName specified for this template.\n[GDS] Derived modalName from hash value.',
                    trackingMethod: 'pageView',
                    type: 'info',
                });
            }

            if (gdsModalName === 'prizeInformation') {
                if (mutation.payload.prizeType) {
                    dimensions.prizeType = i18next.t(`gdsText:informationOverlay.prizeTypes.${mutation.payload.prizeType}`);
                }
                else if (mutation.payload.prizeId) {
                    dimensions.prizeName = i18next.t(`gdsText:informationOverlay.prizeIds.${mutation.payload.prizeId}`);
                }
            }

            if (gdsModalName === 'win') {
                dimensions.screenDetail = i18next.t(`gdsText:screenDetails.${gdsModalName}`);

                if (mutation.payload.awardType === 'piece') {
                    dimensions.gamePiece =
                        i18next.t(`gdsText:gameResultOverlay.pieceNames.${mutation.payload.pieceData.name}`);

                    if (!mutation.payload.win) {
                        gdsModalName = 'gamePieceView';
                    }
                }
                else {
                    let prizeName;
                    if (mutation.payload.awardType === 'booster') {
                        prizeName = mutation.payload.awardObject.booster;
                    }
                    else if (mutation.payload.awardType === 'iwToken') {
                        prizeName = i18next.t('gdsText:gameResultOverlay.prizeNames.iwToken');
                    }
                    else if (mutation.payload.awardType === 'sweeps') {
                        prizeName = i18next.t('gdsText:gameResultOverlay.prizeNames.sweeps');
                    }
                    else {
                        prizeName = mutation.payload.awardObject.id;

                        if (mutation.payload.awardType === 'prizeCaw') {
                            dimensions.screenDetail = i18next.t('gdsText:screenDetails.cawPrizeWin');
                        }
                        else {
                            dimensions.screenDetail = i18next.t('gdsText:screenDetails.prizeWin');
                        }
                    }

                    dimensions.prizeWon = i18next.t(`gdsText:gameResultOverlay.prizeNames.${prizeName}`);
                }
            }
            else {
                dimensions.screenDetail = i18next.t(`gdsText:screenDetails.${gdsModalName}`);
            }
            if (!passiveModalPageViewExceptions.has(gdsModalName)) {
                trackGdsPageView({
                    category: i18next.t(`gdsText:templateNames.${gdsModalName}`),
                    dimensions,
                });
            }
        }
        else if (mutation.type === 'ui/modalClose') {
            if (state.ui.pageName === 'game') {
                setTimeout(() => {
                    const dimensions = {};
                    dimensions.playsRemaining = state.profile.plays_remaining;
                    dimensions.screenDetail = i18next.t('gdsText:screenDetails.game');

                    trackGdsPageView({
                        category: i18next.t('gdsText:templateNames.game'),
                        dimensions,
                    });
                }, 300);
            }
        }
        else if (mutation.type === 'notifications/enableNotification') {
            const notificationName = mutation.payload;

            logGdsMessage({
                item: `Working NOTIFICATION pageName is: ${notificationName}`,
                message: '',
                trackingMethod: 'pageView',
                type: 'info',
            });

            const dimensions = {};
            dimensions.playsRemaining = state.profile.plays_remaining;
            dimensions.screenDetail = i18next.t(`gdsText:screenDetails.notifications.${notificationName}`);
            trackGdsPageView({
                category: i18next.t(`gdsText:templateNames.notifications.${notificationName}`),
                dimensions,
            });
        }

        if (debugGlobalObject && isLocalDev) {
            showGlobalObjectConsoleLog();
        }

    }
    catch (error) {
        console.log(error);
    }
});

// GDS Helper functions
function getGdsEnvironment () {
    const { host } = document.location;
    let environmentCopyNode = 'live';
    if (host.includes('localhost:808') || host.includes('d0rs') || host.includes('promodev')) {
        environmentCopyNode = 'development';
    }
    else if (host.includes('promotest') || host.includes('t0rs')) {
        environmentCopyNode = 'promotest';
    }
    else if (host.includes('review') || host.includes('r0rs')) {
        environmentCopyNode = 'review';
    }
    return i18next.t(`gdsText:globalObject.appInfo.environments.${environmentCopyNode}`);
}

function getGdsPageTemplateName (stateUi) {
    const hash = document.location.hash.replace('#/', '');

    if (stateUi.modalName === 'win') {
        return i18next.t('gdsText:templateNames.gameResults');

    }
    else {
        const gdsTemplateName =
            i18next.t(`gdsText:templateNames.${hash}`) ||
            i18next.t(`gdsText:templateNames.${stateUi.pageName}`) ||
            startCase(stateUi.pageName);
        return gdsTemplateName;
    }
}

function logInToAnalytics (csid) {
    if (isLocalDev) {
        logGdsMessage({
            item: `Bypassing Analytics Login\n[GDS] For csid: ${csid}`,
            // The following spaces after the newline are intentional.
            message: '\n[GDS] Skipping login attempt on localhost.',
            trackingMethod: 'login',
            type: 'info',
        });
    }
    else {
        try {
            window._analytics.login(csid);
        }
        catch (error) {
            console.error(`[GDS] Unable to log in to _analytics with csid ${csid}`);
        }
    }
}

function showGlobalObjectConsoleLog () {
    const consoleStyle = 'color: orange; font-family: "Courier"; font-weight: 400; font-size: 13px;';
    console.info('%c[GDS] Updated the global object.', consoleStyle);
    console.log('%c[GDS] window.uo_data.appInfo', consoleStyle, window.uo_data.appInfo);
    console.log('%c[GDS] window.uo_data.user', consoleStyle, window.uo_data.user);
    if (window.uo_data.experiment) {
        console.log('%c[GDS] window.uo_data.experiment', consoleStyle, window.uo_data.experiment);
    }
}
