const consoleStyle = 'color: orange; font-family: "Courier"; font-weight: 400; font-size: 13px;';

import store from '../store';

export default {
    install (Vue, options) {
        Vue.prototype.$gds = {
            logGdsMessage,
            trackGdsPageView,
        };
    },
};

/**
 * @param {Object} pageData - Contains category and a dimensions {Object}
 * @param {string} pageData.category - The section of the promotion in which this event resides. This can also be safely referred to as the page name.
 * @param {Object} pageData.dimensions - Object containing additional key/value pairs to further describe this page
 */

export function trackGdsPageView (pageData, isLocalDev = _isLocalDev()) {
    let count = 0;
    const debug = true;
    pageData.dimensions = pageData.dimensions || {};
    const
        checkLimit = 20, // Only want to try 20 times maximum.
        _analytics = window._analytics || {},
        urlParamString = store.state.gds.urlParamString || '',
        urlParams = { ...store.state.gds.urlParams } || {},
        interval = setInterval(() => {
            count += 1;
            if ((_analytics && _analytics.loaded && _analytics.trackPageView) || count >= checkLimit || isLocalDev) {
                clearInterval(interval);
                try {
                    const
                        category =
                            urlParamString ?
                                `${pageData.category}?${urlParamString}` :
                                pageData.category,
                        screenName = pageData.category;

                    // Minimum required dimensions for all pageViews.
                    // Not shown here is dimensions.screenDetail but
                    // that must be sent from the template or
                    // the tracking.js watcher.
                    pageData.dimensions.appRegion = store.state.app.userCountry;
                    pageData.dimensions.pageTitle = document.title;
                    if (urlParamString.length > 0) {
                        pageData.dimensions.queryString = urlParamString;
                    }
                    pageData.dimensions.screenName = screenName;
                    pageData.dimensions.pageTemplate = screenName; // Per client, these should match each other.
                    pageData.dimensions.pageReferrer = document.referrer;

                    // Copy all URL querystring params to the pageView dimensions.
                    const dimensions = Object.assign(pageData.dimensions, urlParams) || {};

                    if (isLocalDev) {
                        if (debug) {
                            console.info('%c[GDS] Bypassing call to GDS server.', consoleStyle);
                            _logPageViewTrackingData(category, { dimensions });
                        }
                    }
                    else {
                        _analytics.trackPageView(category, { dimensions });
                    }
                }
                catch (error) {
                    console.warn('[GDS] Unable to connect to analytics', error);
                }
            }
        }, 100);
}

function _logPageViewTrackingData (gdsCategory, data) {
    console.info('%c[GDS]', consoleStyle, '**PAGE VIEW**');
    console.log('%c[GDS] category:', consoleStyle, gdsCategory);
    console.log('%c[GDS] dimensions:', consoleStyle, data.dimensions);
}

function _isLocalDev () {
    // Useful for verification on development and also for preventing communication errors from local dev to the servers used for GDS.
    return document.location.href.match(/localhost:808/);
}

/**
 * logGdsMessage - Allows for distinct logging of GDS Global Object updates.
 * @param {Object} messageInfo
 * @param {string} messageInfo.item The data item involved in the update
 * @param {string} messageInfo.message The status message about the item update
 * @param {string} messageInfo.trackingMethod Methodology used in code generating the message.
 *      Valid values are event, globalObject, or pageView. Default is globalObject.
 * @param {string} messageInfo.type Type of message to log. Valid types are 'error' or 'info'
 */
export function logGdsMessage (messageInfo) {
    if (messageInfo.type === 'error') {
        if (messageInfo.trackingMethod === 'pageView') {
            console.info('%c[GDS] Error firing pageView for ' + messageInfo.item, consoleStyle);
            console.warn(messageInfo.message);
        }
        else {
            console.info('%c[GDS] Error updating global object for ' + messageInfo.item, consoleStyle);
            console.warn(messageInfo.message);
        }
    }
    else {
        console.info(`%c[GDS] Info: ${messageInfo.item} ${messageInfo.message}`, consoleStyle, messageInfo.value ? messageInfo.value : '');
    }
}
