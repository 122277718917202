
import Cookies from 'js-cookie';

import store from '../store/index';

// Asynchronous component definitions for lazy-loading routes.
// https://router.vuejs.org/guide/advanced/lazy-loading.html
// https://webpack.js.org/guides/code-splitting/#dynamic-imports
// https://webpack.js.org/api/module-methods/#magic-comments
const
    ActivitiesPage = async () => import(
        /* webpackChunkName: 'profile' */
        '../views/ActivitiesPage.vue'
    ),
    WelcomePage = async () => import(
        /* webpackChunkName: 'profile' */
        '../views/WelcomePage.vue'
    );

export default [
    { path: '/', redirect: (to) => {
        const onboardingCookie = Cookies.get(`${store.state.app.name}:showOnboarding`);

        // Used to send server-side errors to the '/error' page.
        if (store.state.app.defaultRoute) {
            return store.state.app.defaultRoute;
        }
        // Default routes for logged-in users.
        else if (store.getters['profile/loggedIn']) {
            if (onboardingCookie) {
                return '/welcome';
            }
            else {
                return '/gameboard';
            }
        }

        // Default route for a user we know nothing about.
        return '/landing';
    } },
    { path: '/activities', component: ActivitiesPage },
    { path: '/welcome', component: WelcomePage },
];
