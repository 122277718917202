
import Cookies from 'js-cookie';

import store from '../store/index';

// Asynchronous component definitions for lazy-loading routes.
// https://v3.router.vuejs.org/guide/advanced/lazy-loading.html
// https://webpack.js.org/guides/code-splitting/#dynamic-imports
// https://webpack.js.org/api/module-methods/#magic-comments
const
    GamePage = async () => import(
        /* webpackChunkName: 'game' */
        '../views/GamePage.vue'
    ),
    SkillGamePage = async () => import(
        /* webpackChunkName: 'skillGame' */
        '../views/SkillGamePage.vue'
    ),
    SkillGameInfoPage = async () => import(
        /* webpackChunkName: 'skillGame' */
        '../views/SkillGameInfoPage.vue'
    ),
    SkillGameInstructionsPage = async () => import(
        /* webpackChunkName: 'skillGame' */
        '../views/SkillGameInstructionsPage.vue'
    );

export default [
    { path: '/gameboard',  component: GamePage },
    {
        path: '/holiday-run',
        component: SkillGameInfoPage,
        beforeEnter: (to, from, next) => {
            const onboardingCookie = Cookies.get(`${store.state.app.name}:showSkillGameOnboarding`);
            if (onboardingCookie) {
                next('/holiday-run/instructions');
            }
            else {
                next();
            }
        },
    },
    { path: '/holiday-run/instructions', component: SkillGameInstructionsPage },
    { path: '/holiday-run/play', component: SkillGamePage },
];
