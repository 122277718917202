
    export default {
        name: 'StarbucksForLifeLogo',

        props: {
            color: {
                type: String,
                default: 'green',
                validator: (value) => ['green', 'white'].includes(value),
            },

            size: {
                type: String,
                default: 'small',
                validator: (value) => ['small', 'large'].includes(value),
            },
        },

        computed: {
            imageSource () {
                return this.$i18nAsset(`img/sfl-logo-${this.size}-${this.color}.svg`);
            },
        },
    };
