
    import BaseModal from './BaseModal';

    export default {
        name: 'DoublePlayDayModal',

        extends: BaseModal,

        i18nOptions: { namespaces: ['double_play_day_modal'] },

        props: {
            phase: {
                type: String,
                required: true,
                validator (value) {
                    return ['current', 'future'].includes(value);
                },
            },
        },

        data () {
            return {
                modalName: 'double-play-day',
            };
        },
    };
