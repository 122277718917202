
    import BaseModal from './BaseModal';

    export default {
        name: 'SurpriseFreePlaysModal',

        extends: BaseModal,

        i18nOptions: { namespaces: ['surprise_free_plays_modal'] },

        data () {
            return {
                modalName: 'surprise-free-plays',
            };
        },
    };
