
    import { mapState } from 'vuex';

    import { CloseIcon } from '@components/icons';

    import BaseModal from './BaseModal';

    export default {
        name: 'InstantWinPrizesModal',

        components: {
            CloseIcon,
        },

        extends: BaseModal,

        i18nOptions: { namespaces: ['instant_win_prizes_modal'] },

        data () {
            return {
                modalName: 'instant-win-prizes',
            };
        },

        computed: {
            ...mapState(['award']),

            userPrizes () {
                return this.award.instantWin;
            },
        },

        methods: {
            getBadgeImageSource (prizeId) {
                try {
                    const prizeBadge = this.$t(`prizes.${prizeId}.badge`) || 'general';
                    return require(`../../img/prizes/${prizeBadge}.svg`);
                }
                catch (error) {
                    // Swallow error and return placeholder data URI for missing image
                    return 'data:image/gif;base64,R0lGODlhAQABAIAAAP7//wAAACH5BAAAAAAALAAAAAABAAEAAAICRAEAOw==';
                }
            },
        },
    };
