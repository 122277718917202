
    import CloseIcon from '@components/icons/CloseIcon.vue';
    import StarbucksForLifeLogo from '@components/StarbucksForLifeLogo.vue';

    import BaseModal from './BaseModal';

    const stickersConfig = {"_public":true,"4DzJuLPaYxe8vTqc":2014,"qyYQRqRFGM58SUxB":2015,"UQJRkhE66tEGDSrD":2016,"jeECw7axzkkZ2xMy":2017,"umvg5XJhtPmyGhFH":2018,"yJCTCBQwJZpVdVPM":2019,"NQNkK3u2xn8tChhe":2020,"kFMUhLYC357UtL2L":2021,"gdDH3uzt4ayhcaqb":2022,"5U2rVfNcrs66NPCA":2023};
    delete stickersConfig._public;

    export default {
        name: 'StickerBookModal',

        components: {
            CloseIcon,
            StarbucksForLifeLogo,
        },

        extends: BaseModal,

        i18nOptions: { namespaces: ['stickers'] },

        data () {
            return {
                modalName: 'sticker-book',

                stickersList: Object.values(stickersConfig).toSorted((a, b) =>  a - b),
            };
        },

        methods: {
            getStickerImageSource (sticker) {
                return require(`../../img/stickers/${sticker}.png`);
            },

            hasUnlockedSticker (sticker) {
                const unlockedStickers = JSON.parse(this.profile.unlockedStickers || '[]');
                return unlockedStickers.includes(sticker);
            },
        },
    };
