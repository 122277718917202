
import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Vue from 'vue';
import I18NextVue from 'i18next-vue';
import RSCDBackend from '../../../lib/i18n/rs-cd-backend';
import { browserDetector } from '../../../lib/i18n/vanity-locale';
import tt2TagsPostProcessor from '../../../lib/i18n/tt2-tags-post-processor';
import directive from './directive';

import gdsResources from './gds-resources';
import testResources from './test-resources';

const additionalResources = {
    en: { ...gdsResources.en, ...testResources.en },
    fr: { ...testResources.fr },
};

Vue.use(I18NextVue, { i18next });
Vue.directive('t', directive);

const
    initOpts = {"preload":["en"],"debug":false,"defaultNS":"global","fallbackLng":["en"],"returnNull":true,"returnObjects":true,"ns":["global","links","buttons","share"],"detection":{"lookupQuerystring":"locale","order":["querystring","vanity","navigator","header"],"caches":false},"postProcess":["tt2Tags"]},
    { enable: enableVanityLocale } = {"_public":true,"enable":true,"defaultLocale":"en-US","pairs":[{"pattern":"(fr|starbucksavie\\.ca|-frca)","locale":"fr-CA"},{"pattern":"(\\.ca|-ca)","locale":"en-CA"}]};

const languageDetector = new LanguageDetector();
if (enableVanityLocale) {
    languageDetector.addDetector(browserDetector);
}

export const initPromise = i18next
.use(RSCDBackend)
.use(languageDetector)
.use(tt2TagsPostProcessor)
.init({
    ...initOpts,

    resources: additionalResources,
    // Load additional resource bundles from backend.
    partialBundledLanguages: true,
    // Use empty string for missing keys on the front-end.
    parseMissingKeyHandler: () => '',
    // parseMissingKeyHandler: (key) => `[Missing: ${key}]`,
    // debug: true,
});

export { i18next };
