
    import BaseModal from './BaseModal';

    export default {
        name: 'StickerUnlockedModal',

        extends: BaseModal,

        i18nOptions: { namespaces: ['sticker_unlocked_modal', 'stickers'] },

        props: {
            sticker: {
                type: String,
                required: true,
            },
        },

        data () {
            return {
                modalName: 'sticker-unlocked',
            };
        },

        computed: {
            stickerImageSource () {
                return require(`../../img/stickers/${this.sticker}.png`);
            },
        },
    };
