
    import { mapState, mapGetters, mapActions } from 'vuex';

    import LanguageSelector from '@components/LanguageSelector.vue';

    import { openPopup } from '../popup';

    export default {
        name: 'TheFooter',

        i18nOptions: { namespaces: ['footer', 'links', 'global'] },

        components: {
            LanguageSelector,
        },

        computed: {
            ...mapState([
                'app',
                'profile',
                'ui',
            ]),
            ...mapGetters({
                inPhase: 'app/inPhase',
                loggedIn: 'profile/loggedIn',
            }),

            faqUrl () {
                if (this.app?.siteURL?.includes('localhost')) {
                    return `/?locale=${this.app.locale}#/faq`;
                }

                return '/#/faq';
            },

            shouldShowOamoeLink () {
                return this.loggedIn && !this.inPhase('survey');
            },

            shouldShowReturnToTopButton () {
                return ['game', 'activities', 'skill-game-info'].includes(this.ui.pageName);
            },
        },

        methods: {
            ...mapActions({
                logOut: 'profile/logOut',
            }),

            handleReturnToTopButtonClick () {
                window.scroll({ top: 0, behavior: 'smooth' });
            },

            openPopup,

            async signOut () {
                try {
                    window._analytics.logout();
                }
                catch (error) {
                    console.error('[GDS] Logout unsuccessful');
                }

                await this.logOut();
            },
        },
    };

