
import axios from 'axios';
import { reactive, set } from 'vue';
import api from '../../api';

const getDefaultState = () => reactive({
    awards: null,
    prizeWon: null,
    collectAndWin: {},
    instantWin: [],
    sweeps: 0,
    boosters: {
        heal: 0,
        double_points: 0,
        invincibility: 0,
    },
});

const state = getDefaultState();

const getters = {
    getPieceCountById: (state) => (pieceId) => {
        const tier = `collect_tier${pieceId.slice(4, -2)}`;
        return (state.collectAndWin[tier]) ? state.collectAndWin[tier][pieceId] : 0;
    },

    isFirstPlay (state, getters, rootState, rootGetters) {
        return getters.ownedPieces.length === 0 &&
            state.instantWin.length === 0 &&
            state.sweeps === 0;
    },

    isTierComplete: (state) => (tier) => (state.collectAndWin[tier] ? Object.keys(state.collectAndWin[tier]).length === 3 : false),

    ownedPieces (state) {
        const pieces = [];

        for (const tier in state.collectAndWin) {
            for (const pieceId in state.collectAndWin[tier]) {
                if (state.collectAndWin[tier][pieceId]) {
                    pieces.push(pieceId);
                }
            }
        }

        return pieces;
    },
};

const mutations = {
    updateAwards (state, awards) {
        state.awards = awards;
    },
    updatePrizeWon (state, prizeWon) {
        state.prizeWon = prizeWon;
    },

    updateBoardState (state, { boardState }) {
        // Collect and Win tiers
        set(state, 'collectAndWin', { ...boardState.pieces } || {});

        // Instant Win prizes
        set(state, 'instantWin', []);
        for (const prizeId of boardState.prizeIds) {
            const normalizedPrizeId = prizeId.replace(/-/g, '').toLowerCase();
            state.instantWin.push(normalizedPrizeId);
        }

        state.sweeps = boardState.sweepsCount;

        state.loaded = true;
    },

    updateBoosters (state, { boosters }) {
        set(state, 'boosters', boosters);
    },

    incrementPieceCountById (state, { pieceId }) {
        let count = 0;

        const tier = `collect_tier${pieceId.slice(4, -2)}`;
        if (pieceId in state.collectAndWin[tier]) {
            count = state.collectAndWin[tier][pieceId];
        }

        set(state.collectAndWin[tier], pieceId, count + 1);
    },

    incrementSweeps (state) {
        set(state, 'sweeps', state.sweeps + 1);
    },

    setPieceCountById (state, { pieceId, count }) {
        const tier = `collect_tier${pieceId.slice(4, -2)}`;
        set(state.collectAndWin[tier], pieceId, count);
    },

    addInstantWinPrize (state, { id }) {
        const normalizedPrizeId = id.replace(/-/g, '').toLowerCase();
        state.instantWin.push(normalizedPrizeId);
        return true;
    },
};

const actions = {
    async getAwards ({ dispatch }) {
        return dispatch('makeCall', {
            type: 'get',
            endpoint: 'awards',
        });
    },

    async awardEvent ({ dispatch }, { event }) {
        const data = {};

        return dispatch('makeCall', {
            endpoint: `awards/events/${encodeURIComponent(event)}:award`,
            data,
        });
    },

    async loadBoardState ({ commit }) {
        try {
            const response = await axios.get(`${api.base}/awards/boardState`);
            const boardState = response.data;

            commit('updateBoardState', { boardState });
        }
        catch (error) {
            console.error('could not load board state', error);
            throw error;
        }
    },

    async loadBoosters ({ commit }) {
        try {
            const response = await axios.get(`${api.base}/awards/boosters`);
            const boosters = response.data;

            commit('updateBoosters', { boosters });
        }
        catch (error) {
            console.error('could not load boosters', error);
            throw error;
        }
    },

    async play ({ commit, dispatch, rootState, state }) {
        const data = {};

        const response = await dispatch('makeCall', {
            endpoint: 'awards/:play',
            data,
        });

        const {
            boosterWon,
            iwTokenWon,
            prizeWon,
            pieceWon,
            playsRemaining,
            sweepsWon,
        } = response.data;

        if (prizeWon?.id) {
            prizeWon.id = prizeWon.id.replaceAll('-', '').toLowerCase();
        }

        if (prizeWon !== undefined) {
            commit('updatePrizeWon', prizeWon);
        }

        if (playsRemaining !== undefined) {
            commit('profile/updateProfile', {
                plays_remaining: playsRemaining,
            }, { root: true });
        }

        return {
            boosterWon,
            iwTokenWon,
            pieceWon,
            playsRemaining,
            prizeWon,
            sweepsWon,
        };
    },

    async useBooster ({ commit }, { booster }) {
        let success = false;

        try {
            const response = await axios.post(`${api.base}/awards/${booster}:booster`);

            success = response.status === 201;
            if (success) {
                commit('decreaseBooster', {
                    booster,
                    amount: 1,
                });
            }
        }
        catch (error) {
            console.error('error using skill game booster', booster, error);
        }

        return success;
    },

    async makeCall ({ commit }, {
        type = 'post',
        endpoint,
        data,
    }) {
        let response;

        try {
            response = await axios[type](`${api.base}/${endpoint}`, data);
        }
        catch (err) {
            if (err.response?.status === 429) {
                // User was only limited, carry on
                ({ response } = err);
            }
            else {
                console.error(
                    `error making ${endpoint} call`,
                    err.message,
                    err,
                );

                throw err;
            }
        }

        if (response.data?.awards !== undefined) {
            commit('updateAwards', response.data.awards);
        }

        return response;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
