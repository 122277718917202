
import axios from 'axios';
import { reactive, set } from 'vue';

import api from '../../api';

const charactersConfig = {"4DzJuLPaYxe8vTqc":"waddles","qyYQRqRFGM58SUxB":"rupert","UQJRkhE66tEGDSrD":"penelope"};
const stickersConfig = {"_public":true,"4DzJuLPaYxe8vTqc":2014,"qyYQRqRFGM58SUxB":2015,"UQJRkhE66tEGDSrD":2016,"jeECw7axzkkZ2xMy":2017,"umvg5XJhtPmyGhFH":2018,"yJCTCBQwJZpVdVPM":2019,"NQNkK3u2xn8tChhe":2020,"kFMUhLYC357UtL2L":2021,"gdDH3uzt4ayhcaqb":2022,"5U2rVfNcrs66NPCA":2023};

const getDefaultState = () => reactive({
    boosters: {
        heal: 0,
        double_points: 0,
        invincibility: 0,
    },
});

const state = getDefaultState();

const getters = {};

const mutations = {
    decreaseBooster (state, { booster, amount = 1 }) {
        state.boosters[booster] -= amount;
    },

    increaseAllBoosters (state, { amount }) {
        for (const boosterKey in state.boosters) {
            if (Object.prototype.hasOwnProperty.call(state.boosters, boosterKey)) {
                state.boosters[boosterKey] += amount;
            }
        }
    },

    increaseBooster (state, { booster, amount = 1 }) {
        state.boosters[booster] += amount;
    },

    updateBoosters (state, { boosters }) {
        set(state, 'boosters', boosters);
    },
};

const actions = {
    async loadBoosters ({ commit }) {
        try {
            const response = await axios.get(`${api.base}/awards/boosters`);
            const boosters = response.data;

            commit('updateBoosters', { boosters });
        }
        catch (error) {
            console.error('could not load boosters', error);
            throw error;
        }
    },

    async submitRunStatistics (context, statistics) {
        try {
            const response = await axios.post(`${api.base}/profiles/me/linerunner`, statistics);
            return response.data;
        }
        catch (err) {
            console.error('error submitting line runner statistics', err.message, err);
            throw err;
        }
    },

    async unlockCharacter ({ rootState, commit }, { character }) {
        let success = false;

        const unlockedCharacters = JSON.parse(rootState.profile.unlockedCharacters || '["waddles"]');
        const characterKey = Object.keys(charactersConfig).find(key => charactersConfig[key] === character);

        if (!unlockedCharacters.includes(character) && characterKey) {
            try {
                const response = await axios.post(`${api.base}/profiles/me/unlockCharacter`, {
                    unlockedCharacter: characterKey,
                });

                success = response.status === 201;
                if (success) {
                    unlockedCharacters.push(character);
                    commit('profile/updateProfile', {
                        unlockedCharacters: JSON.stringify(unlockedCharacters),
                    }, { root: true });
                }
            }
            catch (error) {
                console.error('error unlocking line runner character', character, error);
            }
        }

        return success;
    },

    async unlockSticker ({ rootState, commit }, { sticker }) {
        let success = false;

        const unlockedStickers = JSON.parse(rootState.profile.unlockedStickers || '[]');
        const stickerKey = Object.keys(stickersConfig).find(key => stickersConfig[key] === sticker);

        if (!unlockedStickers.includes(sticker) && stickerKey) {
            try {
                const response = await axios.post(`${api.base}/profiles/me/unlockSticker`, {
                    unlockedSticker: stickerKey,
                });

                success = response.status === 201;
                if (success) {
                    unlockedStickers.push(sticker);
                    commit('profile/updateProfile', {
                        unlockedStickers: JSON.stringify(unlockedStickers),
                    }, { root: true });
                }
            }
            catch (error) {
                console.error('error unlocking line runner sticker', sticker, error);
            }
        }

        return success;
    },

    async useBooster ({ commit }, { booster }) {
        let success = false;

        try {
            const response = await axios.post(`${api.base}/awards/${booster}:booster`);

            success = response.status === 201;
            if (success) {
                commit('decreaseBooster', {
                    booster,
                    amount: 1,
                });
                commit('challenges/progressAchievement', {
                    achievementId: 'skillGamePowerUps5',
                }, { root: true });
            }
        }
        catch (error) {
            console.error('error using skill game booster', booster, error);
        }

        return success;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
