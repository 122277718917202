import axios from 'axios';
import Vue from 'vue';

import api from '../../api';

// List challenge IDs that should be excluded from frontend challenge display and/or logic
const achievementsExcludedFromFrontend = new Set([]);

const activitiesExcludedFromFrontend = new Set([
    'doublePlayDay4',
    'doublePlayDay11',
    'doublePlayDay18',
    'doublePlayDay25',
]);

const doublePlayDayChallenges = new Set([
    'doublePlayDay4',
    'doublePlayDay11',
    'doublePlayDay18',
    'doublePlayDay25',
]);

const getDefaultState = () => ({
    loaded: false,
    activities: {},
    achievements: {},
});

const state = getDefaultState();

const getters = {
    areAllAchievementsCompleted (state, getters) {
        return getters.sortedAchievementIds.completed.length > 0 &&
            getters.sortedAchievementIds.claimable.length === 0 &&
            getters.sortedAchievementIds.inProgress.length === 0;
    },

    areAllActivitiesCompleted (state, getters) {
        return getters.sortedActivityIds.completed.length > 0 &&
            getters.sortedActivityIds.claimable.length === 0 &&
            getters.sortedActivityIds.inProgress.length === 0;
    },

    isDoublePlayDay (state, getters, rootState) {
        if (state.loaded) {
            const appNow = new Date(rootState.app.now);

            for (const challengeId of doublePlayDayChallenges) {
                const challenge = state.activities[challengeId];

                if (challenge) {
                    const end = new Date(challenge.properties.endDate);
                    const start = new Date(challenge.properties.startDate);

                    if (appNow >= start && appNow <= end) {
                        return true;
                    }
                }
            }
        }

        return false;
    },

    sortedActivityIds () {
        const sortedActivities = {
            completed: [],
            claimable: [],
            inProgress: [],
        };

        for (const activityId in state.activities) {
            if (!activitiesExcludedFromFrontend.has(activityId)) {
                const activity = state.activities[activityId];

                if (activity.results.completed) {
                    sortedActivities.completed.push(activityId);
                }
                else if (activity.results.progress === activity.properties.target) {
                    sortedActivities.claimable.push(activityId);
                }
                else {
                    sortedActivities.inProgress.push(activityId);
                }
            }
        }

        return sortedActivities;
    },

    sortedAchievementIds () {
        const sortedAchievements = {
            completed: [],
            claimable: [],
            inProgress: [],
        };

        for (const achievementId in state.achievements) {
            if (!achievementsExcludedFromFrontend.has(achievementId)) {
                const achievement = state.achievements[achievementId];

                if (achievement.results.completed) {
                    sortedAchievements.completed.push(achievementId);
                }
                else if (achievement.results.progress === achievement.properties.target) {
                    sortedAchievements.claimable.push(achievementId);
                }
                else {
                    sortedAchievements.inProgress.push(achievementId);
                }
            }
        }

        return sortedAchievements;
    },

    userAchievementIds (state) {
        return Object.keys(state.achievements);
    },

    userAchievements (state) {
        return state.achievements;
    },

    userActivityIds (state) {
        return Object.keys(state.activities);
    },

    userActivities (state) {
        return state.activities;
    },
};

const mutations = {
    resetAchievements (state) {
        state.achievements = {};
    },

    resetActivities (state) {
        state.activities = {};
    },

    resetChallenges (state) {
        state = getDefaultState();
    },

    updateChallenges (state, { challenges }) {
        for (const challenge of challenges) {
            if (challenge.achievementName.startsWith('skillGame')) {
                Vue.set(state.achievements, challenge.achievementName, { ...challenge });
            }
            else {
                Vue.set(state.activities, challenge.achievementName, { ...challenge });
            }
        }

        state.loaded = true;
    },

    progressAchievement (state, { achievementId }) {
        if (achievementId in state.achievements) {
            const { progress } = state.achievements[achievementId].results;
            const { target } = state.achievements[achievementId].properties;
            // use Math.min to prevent us from progressing a challenge past the target
            Vue.set(state.achievements[achievementId].results, 'progress', Math.min(progress + 1, target));
        }
    },

    progressActivity (state, { activityId }) {
        if (activityId in state.activities) {
            const { progress } = state.activities[activityId].results;
            const { target } = state.activities[activityId].properties;
            // use Math.min to prevent us from progressing a challenge past the target
            Vue.set(state.activities[activityId].results, 'progress', Math.min(progress + 1, target));
        }
    },

    completeAchievement (state, { achievementId }) {
        Vue.set(state.achievements[achievementId].results, 'completed', true);
    },

    completeActivity (state, { activityId }) {
        Vue.set(state.activities[activityId].results, 'completed', true);
    },
};

const actions = {
    async loadChallenges ({ commit }) {
        try {
            const response = await axios.get(`${api.base}/challenges/checkAll`);
            const challenges = response.data;

            commit('updateChallenges', { challenges });
        }
        catch (error) {
            console.error('could not load challenges', error);

            throw error;
        }
    },

    async claimActivity ({ commit }, { activityId }) {
        try {
            const response = await axios.post(`${api.base}/challenges/claim`, { challengeId: activityId });
            const { results } = response.data;

            commit('completeActivity', { activityId });

            if (results.properties.award) {
                commit('profile/incrementPlaysRemaining', {
                    amount: results.properties.award,
                }, { root: true });
            }

            return results;
        }
        catch (error) {
            console.error('could not claim activity', error);

            throw error;
        }
    },

    completeAndRewardAchievement ({ state, commit }, { achievementId }) {
        commit('completeAchievement', { achievementId });

        const achievement = state.achievements[achievementId];
        for (const boosterPack of achievement.properties.boosterPack) {
            const [booster, amount] = boosterPack.split(':');
            commit('skillGame/increaseBooster', {
                booster,
                amount: parseInt(amount, 10),
            }, { root: true });
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
