import store from '../store/index';

const
    LandingPage = async () => import(
        /* webpackChunkName: 'survey' */
        '../views/LandingPage.vue'
    ),
    SurveyPage = async () => import(
        /* webpackChunkName: 'survey' */
        '../views/SurveyPage.vue'
    );

export default [
    {
        path: '/',
        redirect: (to) => {
            if (store.getters['profile/loggedIn']) {
                return '/survey';
            }

            return '/landing';
        },
        meta: { activeInPhase: ['survey'] },
    },
    { path: '/landing', component: LandingPage, meta: { public: true, activeInPhase: ['survey'] } },
    { path: '/survey', component: SurveyPage, meta: { activeInPhase: ['survey'] } },
    // catch-all route to make only the routes defined in this file accessible during survey phase
    { path: '/:catchAll(.*)', redirect: '/', meta: { activeInPhase: ['survey'] } },
];
